import { Component } from '@angular/core';

@Component({
  selector: 'app-alert-subscription',
  standalone: true,
  imports: [],
  templateUrl: './alert-subscription.component.html',
  styleUrl: './alert-subscription.component.scss'
})
export class AlertSubscriptionComponent {
  openLinkInNewTab(url: string) {
    window.open(url, '_blank', 'noopener,noreferrer');
  }
}
