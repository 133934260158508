<div class="flex flex-column lg:flex-row justify-content-between gap-3
  bg-blue-100 border-1 border-blue-300 text-blue-900 p-3">
  <div class="flex gap-3">
    <i class="pi pi-check-circle flex align-items-center text-2xl" style="height: 27px;"></i>
    <div class="flex flex-column lg:flex-row gap-2">
      <p class="font-medium">
        Information
      </p>
      <p class="text-blue-700">
        Quis commodo odio aenean sed adipiscing diam. Bibendum enim facilisis
        gravida neque convallis a cras semper.
      </p>
    </div>
  </div>
  <a pRipple class="flex justify-content-end align-items-center gap-2
    font-medium no-underline cursor-pointer">
    <p (click)="openLinkInNewTab('https://app.aiflowcrm.app/')" class="w-max">Learn More</p>
    <i class="pi pi-arrow-right"></i>
  </a>
</div>
