import { DOCUMENT } from '@angular/common';
import {
  Component,
  computed,
  ElementRef,
  HostListener,
  inject,
  OnInit,
  signal,
  ViewChild
} from '@angular/core';
import { environment } from '../../../environments/environment';
import { AuthService } from '@auth0/auth0-angular';
import { AuthStore } from '../../core/auth/auth.store';
import { FormsModule } from '@angular/forms';
import { TranslocoModule } from '@jsverse/transloco';
// Router
import { Router, RouterModule } from '@angular/router';
import { ROUTER_TOKENS } from '../../app.routes';
// Service
import {
  IMenuSideBar,
  IMenuSideBarEntity,
  MenuSidebarService
} from '../menu-sidebar.service';
import { UserService } from '../../core/user/user.service';
import {
  CompanyService,
  ICompanyReturn
} from '../../core/company/company.service';
import { LanguageConfigService } from '../../pattern/language-config/language-config.service';
import { CompChatManageService } from '../../feature/comp-chat-manage/comp-chat-manage.service';
// PrimeNG
import { StyleClassModule } from 'primeng/styleclass';
import { SidebarModule } from 'primeng/sidebar';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { AvatarModule } from 'primeng/avatar';
// Component
import { IUserLabel } from '../../feature/choose-company/choose-company.component';
import { AlertSubscriptionComponent } from '../../ui/alert-subscription/alert-subscription.component';
import { LoadingPageComponent } from '../../ui/loading-page/loading-page.component';
import { AiFlowCrmSubscription } from '@geeesy/type-aiflow';
import { IFeatureAtt } from '../../core/interface/app.interface';

export interface IThemesConfig {
  textColorS: string;
  titleColorS: string;
  bgColorS: string;
  hoverBgColorS: string;
  borderColorS: string;
}

const PRIMENG_COMPONENT = [
  AvatarModule,
  SidebarModule,
  ButtonModule,
  DropdownModule,
  InputTextModule
];
@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    FormsModule,
    RouterModule,
    StyleClassModule,
    TranslocoModule,
    AlertSubscriptionComponent,
    LoadingPageComponent,
    ...PRIMENG_COMPONENT
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent implements OnInit {
  // Injectable Service
  private router = inject(Router);
  private companyService = inject(CompanyService);
  private authStore = inject(AuthStore);
  private auth0 = inject(AuthService);
  private doc = inject(DOCUMENT);
  private userService = inject(UserService);
  private menuService = inject(MenuSidebarService);
  private langService = inject(LanguageConfigService);
  // For Chat Manage (Layout Detail)
  private readonly compChatManageService = inject(CompChatManageService);

  s3BlobPath = environment.s3.pubBlob;

  @ViewChild('testTp') eleTp!: ElementRef;

  // Variable For profile
  // userAtt = signal<IUserLabel>({
  //   userImgPath: '',
  //   fullName: '',
  // });

  userAtt = computed<IUserLabel>(() => {
    const { firstName, lastName, avatarUrl } =
      this.authStore.userSystemSignal();
    const avatarConvertUrl = avatarUrl?.startsWith('https:')
      ? avatarUrl
      : `${this.s3BlobPath}${avatarUrl}`;
    return {
      userImgPath: avatarConvertUrl,
      firstName: firstName as string,
      lastName: lastName as string
    };
  });
  emptyUserPath = computed<boolean>(() => {
    const { avatarUrl } = this.authStore.userSystemSignal();
    return avatarUrl ? false : true;
  });

  // variable subscription alert conditions
  showAlertSubscription = signal<boolean>(false);

  // Company Variable
  companyList = this.companyService.getCompanyListSignal();
  compSelected = signal<ICompanyReturn | undefined | null>(undefined);
  permissionManage = signal<boolean>(this.authStore.systemManage);

  // MenuVariable
  currentMenu = signal<IMenuSideBar[]>(this.menuService.menuBusiness);
  userMenuSideBar: IMenuSideBar[] = this.menuService.menuUser;

  // Class Themes Menu Variable Default [Owner Menu]
  themesConfig = signal<IThemesConfig>({
    textColorS: 'text-800',
    titleColorS: 'text-900',
    bgColorS: 'surface-section',
    hoverBgColorS: 'bg-bluegray-900',
    borderColorS: 'border-bluegray-600'
  });

  // Layout Display Chat (Mobile)
  displaySignal = this.compChatManageService.getDisplayLayout();
  displayMobileSignal = this.compChatManageService.getDisplayMobileLayout();

  isDev = environment.phase === 'dev' || environment.phase === 'local';

  // For Chat Manage (Layout Detail)
  currentConversation = this.compChatManageService.getCurrentConversation();

  packageBizData = signal<any>(undefined);
  packageCompData = signal<any>(undefined);
  loadingState = signal<boolean>(false);

  ngOnInit(): void {
    this.conditionsThemes();
    this.setProfileState();
    this.setLangState();
    // NOTE Check Conditions Subscription //
    this.getBusinessDetail(true);

    if (this.compSelected()) {
      const { id } = this.compSelected() as ICompanyReturn;
      this.getCompDetail(id);
    }
    // ---------------------------------- //
  }

  // Sidebar When Changing Scroll (Sidebar Close)
  @HostListener('window:scroll', ['$event'])
  onScroll(): void {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    if (scrollTop > 0) {
      document.dispatchEvent(
        new KeyboardEvent('keydown', {
          key: 'Escape',
          code: 'Escape',
          keyCode: 27
        })
      );
    }
  }

  // Add Hidden Class
  // Because When Click Burger Button (Delete Hidden Class)
  onClickAddHiddenClass(key?: string) {
    // console.log("key => ", key);

    if (key) {
      if (key === 'profile') {
        const arr = this.menuService.menuCompany.filter(
          (res: any) => res.subMenu
        );
        // console.log("arr => ", arr);
        arr.map((res: any) => {
          const element = document.getElementById(res.label);
          if (!element?.classList.contains('hidden')) {
            element?.classList.add('hidden');
          }
        });
      } else {
        const element = document.getElementById('profile');
        if (!element?.classList.contains('hidden')) {
          element?.classList.add('hidden');
        }
      }
    } else {
      const element = document.getElementById('app-sidebar-2');
      if (!element?.classList.contains('hidden')) {
        element?.classList.add('hidden');
      }
    }
  }

  getBusinessDetail(refresh = false) {
    this.loadingState.set(refresh);
    this.menuService.getBusinessDetail().subscribe((res: any) => {
      console.log('Get Business Detail 🏤🏤🏤', res);
      if (!this.compSelected()) {
        if (!res.subscription) {
          this.showAlertSubscription.set(true);
        } else {
          this.showAlertSubscription.set(false);
        }
        //NOTE check packages list
        this.packageBizData.set(res.subscription);
        this.loadingState.set(false);
      }
    });
  }

  getCompDetail(compId: string) {
    this.loadingState.set(true);
    this.menuService.getCompanyDetail(compId).subscribe((res: any) => {
      console.log('Get Company Detail 🏢🏢🏢', res);
      if (!res.subscription) {
        this.showAlertSubscription.set(true);
      } else {
        this.showAlertSubscription.set(false);
      }
      //NOTE check packages list
      this.packageCompData.set(res.subscription);
      this.loadingState.set(false);
    });
  }

  checkCurrentUrl(path: string) {
    const location = window.location.href;
    return location.includes(path);
  }

  onSelectComp(company: ICompanyReturn) {
    console.warn('Event', company);
    this.companyService.setCompanyState(company);
    // NOTE Check Conditions Subscription //
    this.getCompDetail(company.id);
    // --------------------------------- //
    this.redirectDashboard();
    this.conditionsThemes();
  }

  setProfileState() {
    this.authStore.setUserProfileSystemState(this.authStore.userSystem);
  }

  setLangState() {
    const langState = this.langService.getLangState();
    if (langState) {
      this.langService.switchLanguage(langState);
    }
  }

  conditionsThemes() {
    this.compSelected.set(this.companyService.getCompanyState());
    let themeConfig = {
      textColorS: 'text-800',
      titleColorS: 'text-900',
      bgColorS: 'surface-section',
      hoverBgColorS: 'bg-bluegray-900',
      borderColorS: 'border-bluegray-600'
    };

    if (this.compSelected()) {
      this.currentMenu.set(this.menuService.menuCompany);
      // NOTE Themes In Company
      themeConfig = {
        textColorS: 'text-100',
        titleColorS: 'text-0',
        bgColorS: 'bg-white',
        hoverBgColorS: 'surface-800',
        borderColorS: 'border-bluegray-100'
      };
    } else {
      this.currentMenu.set(this.menuService.menuBusiness);
    }
    this.themesConfig.set(themeConfig);
  }

  conditionsPermission(key: string | any) {
    if (key) {
      return this.menuService.conditionsPermission(key);
    } else {
      return true;
    }
  }

  conditionRole(
    adminCondition = true,
    staffCondition = true,
    ownerConditions = false
  ) {
    const conditionsAdmin = this.menuService.conditionRoleAdmin(adminCondition);
    const conditionsStaff = this.menuService.conditionRoleStaff(staffCondition);
    const conditionsOwner = ownerConditions
      ? this.menuService.conditionsOwnerOnly()
      : true;
    return conditionsAdmin && conditionsStaff && conditionsOwner;
  }

  conditionsOwnerOnly() {
    return this.menuService.conditionsOwnerOnly();
  }

  conditionsSubscriptions(menu: IMenuSideBarEntity) {
    if (menu.isFeature && menu.featuresAtt) {
      // FIXME Conditions for check subscriptions in company detail ===> Comment[Get Company Detail 🏢🏢🏢] .......
      // if (environment.phase === 'prod' || environment.phase === 'rc') {
        return this.compSelected()
          ? this.hasFeatureInCompany(menu.featuresAtt)
          : this.hasFeatureInBusiness(menu.featuresAtt);
      // } else {
      //   return true;
      // }
    } else {
      return true;
    }
  }

  hasFeatureInBusiness(featAtt: IFeatureAtt) {
    if (featAtt && this.packageBizData()) {
      return this.menuService.conditionHasFeatureInSidebar(
        featAtt,
        this.packageBizData()
      );
    } else {
      return false;
    }
  }

  hasFeatureInCompany(featAtt: IFeatureAtt) {
    if (featAtt && this.packageCompData()) {
      return this.menuService.conditionHasFeatureInSidebar(
        featAtt,
        this.packageCompData()
      );
    } else {
      return false;
    }
  }

  async redirectDashboard() {
    if (this.compSelected()) {
      await this.router.navigate([
        `${ROUTER_TOKENS.APP}`,
        `${ROUTER_TOKENS.COMP}`,
        `${ROUTER_TOKENS.HOME}`
      ]);
    } else {
      await this.router.navigate([
        `${ROUTER_TOKENS.APP}`,
        `${ROUTER_TOKENS.BIZ}`,
        `${ROUTER_TOKENS.HOME}`
      ]);
    }
  }

  manageImageEmpty() {
    const firstName = this.userService.manageImageEmpty(
      this.userAtt().firstName
    );
    const lastName = this.userService.manageImageEmpty(this.userAtt().lastName);
    return firstName + lastName;
  }

  manageLayoutChat() {
    if (this.checkCurrentUrl('chat-manage')) {
      if (!this.displaySignal() && this.displayMobileSignal()) {
        return 'p-0';
      } else {
        if (!this.currentConversation()?.isBlock) {
          return 'md:pr-3 py-4';
        } else {
          return 'md:pr-2 py-4';
        }
      }
    } else {
      return 'p-4';
    }
  }

  clearCompanyState() {
    this.compSelected.set(undefined);
    this.companyService.clearCompanyState();
    // NOTE Check Conditions Subscription //
    this.getBusinessDetail(true);
    // --------------------------------- //
    this.redirectDashboard();
    this.conditionsThemes();
  }

  signOut() {
    console.warn('Func | signOut');
    this.authStore.clearState();
    this.auth0.logout({
      logoutParams: {
        returnTo: this.doc.location.origin
      }
    });
  }
}
