<div class="min-h-screen flex {{ checkCurrentUrl('chat-manage') ? 'relative' : 'relative lg:static' }} surface-ground">
  @if(!loadingState()){
    <div id="app-sidebar-2" class="bg-bluegray-800 h-screen {{ checkCurrentUrl('chat-manage') ? 'hidden' : 'hidden lg:block' }}
        flex-shrink-0 {{ checkCurrentUrl('chat-manage') ? 'absolute' : 'absolute lg:sticky' }} left-0 top-0 z-2 select-none
        {{ themesConfig().borderColorS }} border-right-1" style="width: 280px">
      <div class="flex flex-column h-full {{ themesConfig().bgColorS }} transition-all transition-duration-400">
        <div class="flex align-items-center gap-3 mt-3 px-5 cursor-pointer" (click)="redirectDashboard()">
          <img src="assets/icons/icon-main-logo.svg" alt="main-logo" height="40" width="40" />
          <span class="{{ themesConfig().titleColorS }} font-semibold">AIFLOW CRM</span>
        </div>

        <!-- <div class="px-5 mt-3">
          <p class="text-base {{ themesConfig().titleColorS }} font-semibold my-3">
            {{ 'menu.header' | transloco }}
          </p>
        </div> -->
        <div class="mt-6"></div>
        <!-- ANCHOR: TOP MENU -->
        <div class="overflow-y-auto">
          <ul class="list-none px-3 m-0">
            @for (menu of currentMenu(); track menu.path; let i = $index) {
            <!-- FIXME Add Conditions subscription company -->
            @if (menu.subMenu && conditionsPermission(menu.permissionKey) && conditionRole(menu.keyAdmin, menu.keyStaff,
            menu.ownerOnly) && conditionsSubscriptions(menu))
            {
            <li class="menu">
              <a pRipple class="flex align-items-center cursor-pointer p-3 hover:{{ themesConfig().hoverBgColorS }}
                border-round {{ themesConfig().textColorS }} hover:{{ themesConfig().titleColorS }} transition-duration-150
                transition-colors " pStyleClass="@next" enterClass="hidden" enterActiveClass="slidedown"
                leaveToClass="hidden" leaveActiveClass="slideup" (click)="onClickAddHiddenClass('subMenu')"
                (keyup.enter)="onClickAddHiddenClass('subMenu')" tabindex="0">
                <i class="pi mr-3 {{ menu.icon }}"></i>
                <span class="text-base font-normal">{{ menu.label | transloco }}</span>
                <i class="pi pi-chevron-down ml-auto"></i>
              </a>
              <ul #testTp [id]="menu.label" class="list-none py-0 pl-3 pr-0 m-0 hidden overflow-y-hidden transition-all transition-duration-400
                transition-ease-in-out">
                @for (subMenu of menu.subMenu; track subMenu.path) {
                @if (conditionRole(subMenu.keyAdmin, subMenu.keyStaff, subMenu.ownerOnly)) {
                <li class="subMenu" [routerLink]="subMenu.path"
                  routerLinkActive="{{ themesConfig().hoverBgColorS }} border-round" (click)="onClickAddHiddenClass()"
                  (keyup.enter)="onClickAddHiddenClass()" tabindex="0">
                  <a pRipple class="flex align-items-center cursor-pointer p-3 hover:{{ themesConfig().hoverBgColorS }}
                    border-round {{ themesConfig().textColorS }} hover:{{ themesConfig().titleColorS }} transition-duration-150
                    transition-colors">
                    <i class="pi mr-3 {{ subMenu.icon }}"></i>
                    <span class="text-base font-normal">{{ subMenu.label | transloco }}</span>
                  </a>
                </li>
                }
                }
              </ul>
            </li>
            } @else {
            <!-- FIXME Add Conditions subscription company -->
            @if (conditionsPermission(menu.permissionKey) && conditionRole(menu.keyAdmin, menu.keyStaff, menu.ownerOnly) && conditionsSubscriptions(menu))
            {
            <li class="menu" [routerLink]="menu.path" routerLinkActive="{{ themesConfig().hoverBgColorS }} border-round"
              (click)="onClickAddHiddenClass()" (keyup.enter)="onClickAddHiddenClass()" tabindex="0">
              <a pRipple class="flex align-items-center cursor-pointer p-3 hover:{{ themesConfig().hoverBgColorS }} border-round
                {{ themesConfig().textColorS }} hover:{{ themesConfig().titleColorS }} transition-duration-150
                transition-colors">
                <i class="pi mr-3 {{ menu.icon }}"></i>
                <span class="text-base font-normal">{{ menu.label | transloco }}</span>
              </a>
            </li>
            }
            }
            }
          </ul>
        </div>
        <!-- ANCHOR: BOTTOM MENU -->
        <div class="mt-auto">
          <hr class="mb-3 mx-3 border-top-1 border-none {{ themesConfig().borderColorS }}" />
          <ul id="profile"
            class="list-none p-2 m-0 hidden origin-bottom animation-duration-150 overflow-hidden animation-ease-in-out">
            @if (compSelected() && permissionManage()) {
            <!-- NOTE Menu System manage -->
            <li class="menu" (click)="clearCompanyState(); onClickAddHiddenClass();"
              (keyup.enter)="clearCompanyState(); onClickAddHiddenClass();" tabindex="0">
              <a pRipple class="flex align-items-center cursor-pointer p-3 hover:{{ themesConfig().hoverBgColorS }}
                border-round {{ themesConfig().textColorS }} hover:{{ themesConfig().titleColorS }} transition-duration-150
                transition-colors">
                <i class="pi mr-3 pi-briefcase"></i>
                <span class="text-base font-normal">{{ 'menu.management' | transloco }}</span>
              </a>
            </li>
            }
            <!--NOTE Conditions Change Style User Menu Fix style error -->
            @if (compSelected()) {
            @for (menuUser of userMenuSideBar; track menuUser.path) {
            <li class="menu" [routerLink]="menuUser.path" routerLinkActive="surface-800 border-round"
              (click)="onClickAddHiddenClass()" (keyup.enter)="onClickAddHiddenClass()" tabindex="0">
              <a pRipple class="flex align-items-center cursor-pointer p-3 hover:surface-800 border-round text-100 hover:text-0
                transition-duration-150 transition-colors">
                <i class="pi mr-3 {{ menuUser.icon }}"></i>
                <span class="text-base font-normal">{{ menuUser.label | transloco }}</span>
              </a>
            </li>
            }
            <!--NOTE Menu Sign out -->
            <li (click)="signOut()" (keyup.enter)="signOut()" tabindex="0">
              <a pRipple class="flex align-items-center cursor-pointer p-3 hover:surface-800 border-round text-100 hover:text-0
                transition-duration-150 transition-colors">
                <i class="pi mr-3 pi-sign-out"></i>
                <span class="text-base font-normal">{{ 'menu.sign_out' | transloco }}</span>
              </a>
            </li>
            } @else {
            <!-- Black -->
            @for (menuUser of userMenuSideBar; track menuUser.path) {
            <li class="menu" [routerLink]="menuUser.path" routerLinkActive="bg-bluegray-900 border-round">
              <a pRipple class="flex align-items-center cursor-pointer p-3 hover:bg-bluegray-900 border-round text-800 hover:text-900
                transition-duration-150 transition-colors">
                <i class="pi mr-3 {{ menuUser.icon }}"></i>
                <span class="text-base font-normal">{{ menuUser.label | transloco }}</span>
              </a>
            </li>
            }
            <!--NOTE Menu Sign out -->
            <li (click)="signOut()" (keyup.enter)="signOut()" tabindex="0">
              <a pRipple class="flex align-items-center cursor-pointer p-3 hover:{{ themesConfig().hoverBgColorS }}
                border-round text-800 hover:text-900 transition-duration-150 transition-colors">
                <i class="pi mr-3 pi-sign-out"></i>
                <span class="text-base font-normal">{{ 'menu.sign_out' | transloco }}</span>
              </a>
            </li>
            }
          </ul>
          <a pRipple class="m-3 px-3 py-2 flex align-items-center gap-2 hover:{{
              themesConfig().hoverBgColorS
            }} border-round cursor-pointer {{
              themesConfig().textColorS
            }} hover:{{
              themesConfig().titleColorS
            }} transition-duration-150 transition-colors" pStyleClass="@prev" enterClass="hidden"
            enterActiveClass="slidedown" leaveToClass="hidden" leaveActiveClass="slideup"
            (click)="onClickAddHiddenClass('profile')" (keyup.enter)="onClickAddHiddenClass('profile')" tabindex="0">
            @if (this.emptyUserPath()) {
            <p-avatar #imgUser [image]="userAtt().userImgPath" shape="circle" styleClass="flex text-color"
              (onImageError)="imgUser['label'] = manageImageEmpty()" />
            } @else {
            <p-avatar #imgUser [image]="userAtt().userImgPath" shape="circle" styleClass="flex text-color" />
            }
            <span class="text-base font-normal line-clamp-1">{{ userAtt().firstName }} {{ userAtt().lastName }}</span>
            <i class="pi pi-chevron-up text-xs ml-auto"></i>
          </a>
        </div>
      </div>
    </div>
    <div class="min-h-screen flex flex-column relative flex-auto">
      @if ((!displaySignal() && !displayMobileSignal())
      || (displaySignal() && !displayMobileSignal())
      || (displaySignal() && displayMobileSignal())) {
      <div class="flex {{ companyList()!.length ? checkCurrentUrl('chat-manage') ? 'flex-row-reverse justify-content-end' :
        'flex-row-reverse justify-content-end lg:flex-row lg:justify-content-start' : '' }}
        align-items-center px-4 shadow-2 {{ checkCurrentUrl('chat-manage') ? 'relative' : 'relative lg:static' }}
        z-3 surface-border bg-white" style="height: 72px">
        <!-- NOTE Company Options -->
        <!-- @if (companyList()!.length > 0) { -->
        <p-dropdown [options]="companyList()" [(ngModel)]="compSelected" optionLabel="name"
          [placeholder]="'company.select' | transloco" styleClass="w-15rem sm:w-20rem"
          [disabled]="companyList()!.length === 0" (onChange)="onSelectComp($event.value)">
          <ng-template pTemplate="selectedItem">
            @if (compSelected()) {
            <div class="flex align-items-center gap-2">
              <div class="w-2rem h-2rem border-1 border-round-md border-800">
                <img #imgCompanySelected [src]="
                  compSelected()!.avatarUrl
                    ? s3BlobPath + compSelected()!.avatarUrl
                    : 'assets/images/empty/empty-company.png'
                  " alt="company" class="object-cover border-round-md w-full h-full"
                  (error)="imgCompanySelected.src = 'assets/images/empty/empty-company.png'" />
              </div>
              <div class="itemImage">{{ compSelected()!.name }}</div>
            </div>
            }
          </ng-template>
          <ng-template let-company pTemplate="item">
            <div class="flex align-items-center gap-2">
              <div class="w-2rem h-2rem border-1 border-round-md border-800">
                <img #imgCompany [src]="
                  company.avatarUrl
                    ? s3BlobPath + company.avatarUrl
                    : 'assets/images/empty/empty-company.png'
                  " alt="company" class="object-cover border-round-md w-full h-full"
                  (error)="imgCompany.src = 'assets/images/empty/empty-company.png'" />
              </div>
              <div class="itemImage">{{ company.name }}</div>
            </div>
          </ng-template>
        </p-dropdown>
        <!-- } -->
        <!-------------------------->
        <!--NOTE Hamburger menu -->
        <a pRipple class="cursor-pointer inline-flex justify-content-center align-items-center
          {{ checkCurrentUrl('chat-manage') ? '' : 'lg:hidden' }} text-700 mr-4" pStyleClass="#app-sidebar-2"
          enterClass="hidden" enterActiveClass="fadeinleft" leaveToClass="hidden" leaveActiveClass="fadeoutleft"
          [hideOnOutsideClick]="true" [hideOnEscape]="true">
          <i class="pi pi-bars text-4xl"></i>
        </a>
        <!------------------------>
      </div>
      }
      @if (showAlertSubscription()) {
      <app-alert-subscription></app-alert-subscription>
      }
      <!-- isBlock For Chat Manage (Layout Detail) -->
      <div class="flex flex-column flex-auto bg-white {{ manageLayoutChat() }}">
        <router-outlet></router-outlet>
      </div>
    </div>
  }@else{
    <div class="flex justify-content-center align-items-center w-full h-full">
      <app-loading-page  />
    </div>
  }
</div>
